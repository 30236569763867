import React, { useEffect, useState } from "react";
import Big from "big.js";
import { useDebounce } from "react-use";
import { NumberFormatValues, SourceInfo } from "react-number-format";
import { useTranslation } from "react-i18next";
import { SInputContainer, SInputField, SLabel } from "./styles";
import { useGlobalConfigs, useInitialApplication } from "../../../store";
import { CurrencyInput } from "..";

export const ApplicationAmount: React.FC = () => {
  const { t } = useTranslation();
  const [localApplicationAmount, setLocalApplicationAmount] = useState(100);
  const { initialApplication, setInitialApplication } = useInitialApplication();
  const { initialApplication: initialApplicationLimits } = useGlobalConfigs();

  const handleChange = (
    maskedInputValue: NumberFormatValues,
    sourceInfo: SourceInfo
  ): void => {
    // onValueChange is called on EVERY change (unlike onChange) not only on events.
    // When value is fully deleted by the user, sourceinfo.source equals to "props" and not "event"
    if (
      sourceInfo.source === "event" ||
      (sourceInfo.source === "prop" && maskedInputValue.floatValue === 0)
    ) {
      setLocalApplicationAmount(maskedInputValue.floatValue || 0);
    }
  };

  const handleUpdateInitialApplication = (): void => {
    if (localApplicationAmount !== initialApplication) {
      if (localApplicationAmount > initialApplicationLimits.max) {
        setLocalApplicationAmount(initialApplicationLimits.max);
        setInitialApplication(initialApplicationLimits.max);
        return;
      }

      if (localApplicationAmount < initialApplicationLimits.min) {
        setLocalApplicationAmount(initialApplicationLimits.min);
        setInitialApplication(initialApplicationLimits.min);
        return;
      }

      setInitialApplication(Big(localApplicationAmount).toNumber());
    }
  };

  const [, cancelInitialApplicationDebounce] = useDebounce(
    () => {
      handleUpdateInitialApplication();
    },
    1250,
    [localApplicationAmount]
  );

  const handleEnterPress: React.KeyboardEventHandler = (event): void => {
    if (event.key === "Enter") {
      handleUpdateInitialApplication();
      cancelInitialApplicationDebounce();
    }
  };

  useEffect(() => {
    setLocalApplicationAmount(initialApplication);
  }, [initialApplication]);

  return (
    <SInputContainer>
      <SLabel>{t("applicationAmount.initialApplication")}</SLabel>
      <CurrencyInput
        onValueChange={handleChange}
        onKeyDown={handleEnterPress}
        value={Big(localApplicationAmount).toNumber()}
        customInput={SInputField}
        disableUnderline
      />
    </SInputContainer>
  );
};
