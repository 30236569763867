import React, { useEffect, useState, useRef } from "react";
import Big from "big.js";
import { useTheme } from "styled-components";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NumberFormatValues, SourceInfo } from "react-number-format";
import {
  SCard,
  SCardColumn1,
  SCardColumn2,
  SCardRow1,
  SCardRow2,
  SCardRow3,
  SCardRow4,
  SCardRow5,
  SCardTitle,
  SCardSubTitle,
  SCardLabelPercentage,
  SCardLabelAmount,
  SCardPercentageSlider,
  SCardInputAmount,
  SCardHomeIcon,
  SCardCheckBox,
  SCardHogIcon,
  SCardBagIcon,
  SCardBuildIcon,
  SCardWrapper,
  SAssetControls,
  SDeleteButton,
  STrashCanIcon,
  SCardInputPercentage,
  SLockButton,
  SLockOpenIcon,
  SLockClosedIcon,
  SRedLockIcon,
  SCardBarIcon,
  SCardPercentageContainer,
  SIconContainer,
} from "./styles";
import {
  AssetData,
  useCurrentPortfolio,
  useLanguage,
  useSideEditor,
} from "../../../../store";
import { colorFromAssetType, CurrencyInput } from "../..";
import { translateCategory } from "../../../../utils";

interface PortfolioEditorAssetItemPropsType {
  asset: AssetData;
  assetKey: string;
}

export const PortfolioEditorAssetItem: React.FC<
  PortfolioEditorAssetItemPropsType
> = ({ asset, assetKey }) => {
  const [language] = useLanguage();
  const { t } = useTranslation();
  const theme = useTheme();
  const [isCardSelected, setIsCardSelected] = useState(false);
  const [assetInvestmentValue, setAssetInvestmentValue] = useState(
    asset.amount
  );
  const [sliderValue, setSliderValue] = useState(asset.percentage);
  const [assetPercentage, setAssetPercentage] = useState(asset.percentage);
  const assetType = translateCategory(asset.asset.portfolioCategory);
  const {
    updatePortfolioAsset,
    removePortfolioAsset,
    updatePortfolioAssetLock,
    removeCurrentPortfolio,
    isLastAssetInPortfolio,
    isLastPortfolio,
    isAnyAssetLocked,
  } = useCurrentPortfolio();
  const navigate = useNavigate();
  const { setPortfolioBeingEdited } = useSideEditor();
  const rangePercentageRef = useRef<HTMLInputElement>(null);

  const handleCardSelected = (): void => {
    setIsCardSelected((currentCardSelected) => !currentCardSelected);
  };

  const handleDeleteAsset = (): void => {
    removePortfolioAsset(assetKey);
    if (isLastPortfolio && isLastAssetInPortfolio) {
      navigate("../");
    }
    if (isLastAssetInPortfolio) {
      setPortfolioBeingEdited(null);
      removeCurrentPortfolio();
    }
  };

  const handleLockAsset = (): void =>
    updatePortfolioAssetLock(assetKey, !asset.isLocked);

  const handlePercentageFocus = (): void => {
    if (rangePercentageRef?.current) {
      setTimeout(() => {
        const input = rangePercentageRef?.current as HTMLInputElement;
        const valueSize = String(input.value).length;
        input.select();
        input.selectionEnd = valueSize - 2;
        input.selectionStart = 0;
      }, 0);
    }
  };

  const handleAssetPercentage = (
    maskedInputValue: NumberFormatValues,
    sourceInfo: SourceInfo
  ): void => {
    // onValueChange is called on EVERY change (unlike onChange) not only on events.
    // When value is fully deleted by the user, sourceinfo.source equals to "props" and not "event"
    if (!asset.isLocked) {
      if (
        sourceInfo.source === "event" ||
        (sourceInfo.source === "prop" && maskedInputValue.floatValue === 0)
      ) {
        setAssetPercentage(maskedInputValue.floatValue || 0);
      }
    }
  };

  const handleAssetValue = (
    maskedInputValue: NumberFormatValues,
    sourceInfo: SourceInfo
  ): void => {
    // onValueChange is called on EVERY change (unlike onChange) not only on events.
    // When value is fully deleted by the user, sourceinfo.source equals to "props" and not "event"
    if (
      sourceInfo.source === "event" ||
      (sourceInfo.source === "prop" && maskedInputValue.floatValue === 0)
    ) {
      const amountValue = maskedInputValue.floatValue;
      setAssetInvestmentValue(amountValue || 0);
    }
  };

  const handleSlide = (_event: Event, newValue: number | number[]): void => {
    if (!asset.isLocked) {
      setSliderValue(Number(newValue));
    }
  };

  useDebounce(
    () => {
      setAssetPercentage(Number(sliderValue));
    },
    10,
    [sliderValue]
  );

  const handleUpdateAssetInvestmentValue = (): void => {
    if (assetInvestmentValue !== asset.amount) {
      updatePortfolioAsset(
        "RULE_ADD_ASSET_INVESTMENT_VALUE",
        asset.asset,
        asset.percentage,
        assetInvestmentValue,
        asset.isLocked
      );
    }
  };

  const [, cancelInvestmentValueDebounce] = useDebounce(
    () => {
      handleUpdateAssetInvestmentValue();
    },
    1250,
    [assetInvestmentValue]
  );

  const handleUpdatePercentageValue = (): void => {
    if (assetPercentage !== asset.percentage) {
      setSliderValue(
        Big(assetPercentage || 0)
          .round(2)
          .toNumber()
      );
      updatePortfolioAsset(
        "RULE_EDIT_ASSET_PERCENTAGE",
        asset.asset,
        assetPercentage,
        null,
        asset.isLocked
      );
    }
  };

  const [, cancelPercentageDebounce] = useDebounce(
    () => {
      if (assetPercentage !== asset.percentage) {
        setSliderValue(
          Big(assetPercentage || 0)
            .round(2)
            .toNumber()
        );
        updatePortfolioAsset(
          "RULE_EDIT_ASSET_PERCENTAGE",
          asset.asset,
          assetPercentage,
          null,
          asset.isLocked
        );
      }
    },
    1250,
    [assetPercentage]
  );

  const handlePercentageEnterPress: React.KeyboardEventHandler = (
    event
  ): void => {
    if (event.key === "Enter") {
      handleUpdatePercentageValue();
      cancelPercentageDebounce();
    }
  };

  const handleInvestmentValueEnterPress: React.KeyboardEventHandler = (
    event
  ): void => {
    if (event.key === "Enter") {
      handleUpdateAssetInvestmentValue();
      cancelInvestmentValueDebounce();
    }
  };

  useEffect(() => {
    setAssetPercentage(asset.percentage);
    setSliderValue(asset.percentage || 0);
  }, [asset.percentage]);

  useEffect(() => {
    setAssetInvestmentValue(asset.amount);
  }, [asset.amount]);

  return (
    <SCardWrapper>
      <SCard $selected={isCardSelected}>
        <SCardColumn1>
          <SIconContainer
            $backgroundColor={colorFromAssetType(
              asset.asset.portfolioCategory,
              theme
            )}
          >
            {asset.asset.portfolioCategory === "ACAO" && <SCardBagIcon />}
            {asset.asset.portfolioCategory === "FUNDO" && <SCardHomeIcon />}
            {asset.asset.portfolioCategory === "EUROPA" && <SCardHomeIcon />}
            {asset.asset.portfolioCategory === "RENDA_FIXA" && <SCardHogIcon />}
            {asset.asset.portfolioCategory === "BENCHMARK_PERSONALIZADO" && (
              <SCardBarIcon />
            )}
            {asset.asset.portfolioCategory === "PREVIDENCIA" && (
              <SCardBuildIcon />
            )}
          </SIconContainer>
        </SCardColumn1>

        <SCardColumn2>
          <SCardRow1>
            <SCardTitle>{asset.asset.label}</SCardTitle>
            <SCardCheckBox
              checked={isCardSelected}
              onChange={handleCardSelected}
            />
          </SCardRow1>
          <SCardRow2>
            <SCardSubTitle>{assetType}</SCardSubTitle>
          </SCardRow2>
          <SCardRow3>
            <SCardPercentageContainer>
              <SCardLabelPercentage>
                {t("assetItem.walletPercentage")}
              </SCardLabelPercentage>
              {asset.isLocked ? <SRedLockIcon /> : null}
            </SCardPercentageContainer>

            <SCardLabelAmount>
              {t("assetItem.investmentAmount")}
            </SCardLabelAmount>
          </SCardRow3>
          <SCardRow4>
            <SCardInputPercentage
              onKeyDown={handlePercentageEnterPress}
              value={Big(assetPercentage || 0)
                .round(2)
                .toNumber()}
              suffix=" %"
              decimalSeparator={language === "en" ? "." : ","}
              thousandSeparator={language === "en" ? "," : "."}
              decimalScale={2}
              onValueChange={handleAssetPercentage}
              onFocus={handlePercentageFocus}
              getInputRef={rangePercentageRef}
              $selected={isCardSelected}
            />

            <CurrencyInput
              disabled={isAnyAssetLocked}
              onKeyDown={handleInvestmentValueEnterPress}
              value={Big(assetInvestmentValue || 0).toNumber()}
              onValueChange={handleAssetValue}
              customInput={SCardInputAmount}
              $selected={isCardSelected}
              disableUnderline
            />
          </SCardRow4>
          <SCardRow5>
            <SCardPercentageSlider
              disabled={asset.isLocked}
              min={0}
              max={100}
              onChange={handleSlide}
              value={Big(sliderValue || 0)
                .round(2)
                .toNumber()}
            />
          </SCardRow5>
        </SCardColumn2>
      </SCard>
      {isCardSelected && (
        <SAssetControls>
          <SLockButton onClick={handleLockAsset}>
            {asset.isLocked ? <SLockClosedIcon /> : <SLockOpenIcon />}
            {asset.isLocked ? t("assetItem.unlock") : t("assetItem.lock")}
          </SLockButton>
          <SDeleteButton onClick={handleDeleteAsset}>
            <STrashCanIcon />
            {t("assetItem.delete")}
          </SDeleteButton>
        </SAssetControls>
      )}
    </SCardWrapper>
  );
};
